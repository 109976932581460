html, body {
    height: 100%;
}

.quipment-login {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.quipment-login > header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 3rem 0;
}

.quipment-login > header > div {
    background-image: url('../img/logo-quipment-black.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    box-sizing: content-box;
    display: inline-block;
    height: 62px;
    text-indent: -9999px;
    width: 172px;
}

.quipment-login > main {
    align-items: center;
    background-color: #ffe600;
    background-image: url('../img/background-banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 3rem;
    padding: 2rem 0;
}

.quipment-login > main > * {
    width: 24rem;
    margin: 0 auto;
}

.quipment-login > main > header > h1 {
    border-bottom: 1px solid #b0b0b0;
    font-weight: bold;
    padding-bottom: 2rem;
    margin-bottom: 3rem;
    text-align: center;
}

.btn-lg {
    padding: 0.5rem;
    font-size: 0.8rem;
}

.btn-primary {
    background-color: #333;
    background-image: none;
    border: 1px solid #000 !important;
    color: #fff
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:focus:focus,
.btn-primary:hover {
    background-color: #000;
}

#kc-form-buttons {
    margin-top: 2rem;
}

#kc-locale,
#kc-locale-wrapper {
    width: 100%;
}

#kc-locale ul {
    background-color: var(--pf-global--BackgroundColor--100);
    display: none;
    top: 20px;
    min-width: 100px;
    padding: 0;
}

#kc-locale-dropdown{
    display: inline-block;
}

#kc-locale-dropdown:hover ul {
    display:block;
}

#kc-locale-dropdown a {
    color: var(--pf-global--Color--200);
    text-align: right;
    font-size: var(--pf-global--FontSize--sm);
}

#kc-locale-dropdown button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: var(--pf-global--Color--200);
    text-align: right;
    font-size: var(--pf-global--FontSize--sm);
}

@media (min-width: 768px) {
    #kc-locale {
        position: relative;
        text-align: right;
        z-index: 9999;
    }
}

@media (max-width: 767px) {
    .quipment-login > main > * {
        width: 20rem;
        margin: 0 auto;
    }

    #kc-locale {
        position: absolute;
        width: 200px;
        top: 20px;
        right: 20px;
        text-align: right;
        z-index: 9999;
    }
}

.pf-c-button.pf-m-control:hover::after {
    border-bottom-color: #000;
}

.pf-c-form-control {
    padding-right: 2rem;
}

.pf-c-form__helper-text {
    display: block;
    width: 100%;
}

.required {
    color: #a30000;
}

a {
    color: #333;
}

a:focus, a:hover {
    color: #000;
}

footer {
    align-items: center;
    background: #404040;
    color: #fff;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    height: 8rem;
    justify-content: space-around;
    margin: 0;
    margin-top: auto !important;
    padding: 0;
    position: relative;
}

@media (min-width: 576px) {
    footer:after, footer:before {
        border-style: solid;
        content: "";
        display: block;
        height: 0;
        position: absolute;
        top: -40px;
        width: 0
    }

    footer:before {
        border-color: transparent transparent #404040;
        border-width: 0 0 40px 144px;
        left: 0
    }
}

@media (min-width: 576px) and (min-width:768px) {
    footer:before {
        border-width:0 0 40px 192px
    }
}

@media (min-width: 576px) and (min-width:992px) {
    footer:before {
        border-width:0 0 40px 248px
    }
}

@media (min-width: 576px) and (min-width:1200px) {
    footer:before {
        border-width:0 0 40px 300px
    }
}

@media (min-width: 576px) {
    footer:after {
        border-color:transparent transparent transparent #404040;
        border-width: 40px 0 0 432px;
        left: 143px
    }
}

@media (min-width: 576px) and (min-width:768px) {
    footer:after {
        border-width:40px 0 0 576px;
        left: 191px
    }
}

@media (min-width: 576px) and (min-width:992px) {
    footer:after {
        border-width:40px 0 0 744px;
        left: 247px
    }
}

@media (min-width: 576px) and (min-width:1200px) {
    footer:after {
        border-width:40px 0 0 900px;
        left: 299px
    }
}

footer .logo {
    background-image: url('../img/logo-quipment-white.png');
    background-repeat: no-repeat;
    background-size: contain;
    box-sizing: content-box;
    display: inline-block;
    height: 56px;
    margin: 0.5rem;
    text-indent: -9999px;
    width: 182px
}

#kc-info-message {
    font-size: 16px;
}

footer .btn-primary {
    background-color: #ffe600;
    color: #000000;
    border-radius: 0.375rem;
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
}
