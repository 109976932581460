html, body {
  height: 100%;
}

.quipment-login {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.quipment-login > header {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
  display: flex;
}

.quipment-login > header > div {
  box-sizing: content-box;
  height: 62px;
  text-indent: -9999px;
  width: 172px;
  background-image: url("logo-quipment-black.7c126eaf.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

.quipment-login > main {
  background-color: #ffe600;
  background-image: url("background-banner.a757d3f0.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  padding: 2rem 0;
  display: flex;
}

.quipment-login > main > * {
  width: 24rem;
  margin: 0 auto;
}

.quipment-login > main > header > h1 {
  text-align: center;
  border-bottom: 1px solid #b0b0b0;
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  font-weight: bold;
}

.btn-lg {
  padding: .5rem;
  font-size: .8rem;
}

.btn-primary {
  color: #fff;
  background-color: #333;
  background-image: none;
  border: 1px solid #000 !important;
}

.btn-primary:active, .btn-primary:focus, .btn-primary:focus:focus, .btn-primary:hover {
  background-color: #000;
}

#kc-form-buttons {
  margin-top: 2rem;
}

#kc-locale, #kc-locale-wrapper {
  width: 100%;
}

#kc-locale ul {
  background-color: var(--pf-global--BackgroundColor--100);
  min-width: 100px;
  padding: 0;
  display: none;
  top: 20px;
}

#kc-locale-dropdown {
  display: inline-block;
}

#kc-locale-dropdown:hover ul {
  display: block;
}

#kc-locale-dropdown a {
  color: var(--pf-global--Color--200);
  text-align: right;
  font-size: var(--pf-global--FontSize--sm);
}

#kc-locale-dropdown button {
  cursor: pointer;
  color: var(--pf-global--Color--200);
  text-align: right;
  font-size: var(--pf-global--FontSize--sm);
  background: none;
  border: none;
  padding: 0;
}

@media (min-width: 768px) {
  #kc-locale {
    text-align: right;
    z-index: 9999;
    position: relative;
  }
}

@media (max-width: 767px) {
  .quipment-login > main > * {
    width: 20rem;
    margin: 0 auto;
  }

  #kc-locale {
    width: 200px;
    text-align: right;
    z-index: 9999;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.pf-c-button.pf-m-control:hover:after {
  border-bottom-color: #000;
}

.pf-c-form-control {
  padding-right: 2rem;
}

.pf-c-form__helper-text {
  width: 100%;
  display: block;
}

.required {
  color: #a30000;
}

a {
  color: #333;
}

a:focus, a:hover {
  color: #000;
}

footer {
  color: #fff;
  height: 8rem;
  background: #404040;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 2rem;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  margin-top: auto !important;
}

@media (min-width: 576px) {
  footer:after, footer:before {
    content: "";
    height: 0;
    width: 0;
    border-style: solid;
    display: block;
    position: absolute;
    top: -40px;
  }

  footer:before {
    border-width: 0 0 40px 144px;
    border-color: #0000 #0000 #404040;
    left: 0;
  }
}

@media (min-width: 576px) and (min-width: 768px) {
  footer:before {
    border-width: 0 0 40px 192px;
  }
}

@media (min-width: 576px) and (min-width: 992px) {
  footer:before {
    border-width: 0 0 40px 248px;
  }
}

@media (min-width: 576px) and (min-width: 1200px) {
  footer:before {
    border-width: 0 0 40px 300px;
  }
}

@media (min-width: 576px) {
  footer:after {
    border-width: 40px 0 0 432px;
    border-color: #0000 #0000 #0000 #404040;
    left: 143px;
  }
}

@media (min-width: 576px) and (min-width: 768px) {
  footer:after {
    border-width: 40px 0 0 576px;
    left: 191px;
  }
}

@media (min-width: 576px) and (min-width: 992px) {
  footer:after {
    border-width: 40px 0 0 744px;
    left: 247px;
  }
}

@media (min-width: 576px) and (min-width: 1200px) {
  footer:after {
    border-width: 40px 0 0 900px;
    left: 299px;
  }
}

footer .logo {
  box-sizing: content-box;
  height: 56px;
  text-indent: -9999px;
  width: 182px;
  background-image: url("logo-quipment-white.fdca24da.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin: .5rem;
  display: inline-block;
}

#kc-info-message {
  font-size: 16px;
}

footer .btn-primary {
  color: #000;
  background-color: #ffe600;
  border-radius: .375rem;
  padding: .375rem .75rem;
  font-size: .9rem;
  font-weight: 400;
}

/*# sourceMappingURL=login.css.map */
